<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewTrailerForm"
        ref="addNewTrailerForm"
        v-model="isFormValid"
        class="custom-form pt-8"
        @submit.prevent="submitNewTrailerForm"
      >
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="trailer.departmentId"
              :items="departmentsList"
              outlined
              :rules="[rules.required]"
              label="Oddział"
              item-text="name"
              item-value="id"
              placeholder="Wybierz oddział"
              dense
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="trailer.licensePlate"
              outlined
              label="Numer rejestraacyjny"
              :rules="[rules.required]"
              placeholder="Wpisz numer rejestracyjny"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="trailer.maxWeight"
              outlined
              label="DMC"
              placeholder="Wpisz DMC"
            />
          </v-col>
          <v-col
            cols="12"
            class="mb-0 pb-0"
          >
            <v-text-field
              v-model="trailer.additionalInfo"
              outlined
              label="Dodatkowe informacje"
              placeholder="Wpisz dodatkowe informacje"
            />
          </v-col>
          <v-col
            cols="12"
            class="my-0 py-0"
          >
            <span class="input-label">
              Aktywny
            </span>
            <v-switch
              v-model="trailer.active"
              hide-details
              class="ma-0 pa-0"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="!isFormValid"
        type="submit"
        form="addNewTrailerForm"
      >
        Dodaj przyczepę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import rules from '../../utils/validators'
import { parseAsBasicUnit } from '../../utils'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper
  },
  data () {
    return {
      rules,
      isFormValid: true,
      trailer: {
        departmentId: null,
        licensePlate: null,
        active: false,
        maxWeight: null,
        additionalInfo: null,
      }
    }
  },
  computed: {
    ...mapState({
      departmentsList: state => state.departments.items,
      isProcessing: state => state.vehicles.isProcessing
    })
  },
  methods: {
    ...mapActions({
      addNewTrailer: 'trailers/addNewItem',
      getTrailers: 'trailers/getItems',
      closeDialog: 'layout/closeDialog'
    }),
    submitNewTrailerForm () {
      this.$refs.addNewTrailerForm.validate()
      if (this.isFormValid) {
        const maxWeight = parseAsBasicUnit(this.trailer.maxWeight, 1000)
        const params = {
          ...this.trailer,
          maxWeight,
        }
        this.addNewTrailer({ params })
          .then(() => {
            this.getTrailers()
            this.closeDialog()
          })
      }
    }
  }
}
</script>
